import m from 'mithril'

const dice = ['\u2680', '\u2681', '\u2682', '\u2683', '\u2684', '\u2685']
const diceOffset = 50
const diceContainerWidth = 200
const diceCharacterWidth = 28
const diceScrollSize = diceOffset * dice.length
const nextOffset = (time) => {
  if (time >= 1) return 1;

  // https://gist.github.com/gre/1650294
  return (time - 1)*(time - 1)*(time - 1) + 1;
}

const completeRoll = vnode => {
  clearInterval(vnode.state.interval)
  vnode.attrs.oncomplete && vnode.attrs.oncomplete()
}

const DiceRollMessage = {
  oncreate: vnode => vnode.attrs.oncreate && vnode.attrs.oncreate(),
  oninit: vnode => {
    vnode.state.animProgress = 0
    vnode.state.emphasizeDie = false

    vnode.state.interval = setInterval(() => {
      const target = -vnode.attrs.result * diceOffset + diceOffset + diceContainerWidth / 2 - diceCharacterWidth / 2
      const initial = target - 1200
      const delta = target - initial
      
      // If we calculated offset using the above initial variable, it would end up being negative.
      // This would mess with calculations below which expects vnode.state.offset to be positive.
      let positiveInitial = initial;
      while (positiveInitial < 0) positiveInitial += 300
      const positiveOffset = positiveInitial + nextOffset(vnode.state.animProgress) * delta
      vnode.state.offset = positiveOffset

      vnode.state.animProgress += 50 / 1000 / 3

      // Stop updating once the animation has finished
      if (vnode.state.animProgress >= 1) {
        vnode.state.emphasizeDie = true
        completeRoll(vnode)
      }

      m.redraw()
    }, 50)
  },
  onremove: completeRoll,
  view: vnode => m('div', [
    m('.msg', vnode.attrs.heading),
    m('.dice-container', [
      m('.dice-facade'),
      ...dice.map((die, i) => {
        let left = vnode.state.offset + i * diceOffset

        // Keep left within range of [-100,200]
        left %= 300
        if (left > 200) left -= 300

        const emphasize = vnode.state.emphasizeDie && vnode.attrs.result === i + 1
        const desc = emphasize ? '.die.die-emphasize' : '.die'

        return m(desc, { style: { left: left + 'px' } }, die)
      })
    ])
  ])
}

export { DiceRollMessage }
