import './styles.scss'
import m from 'mithril'
import offset from 'document-offset'
import { ChoiceButton, ChoicesList } from './Choice'
import { TextMessage, MessageList } from './TextMessage'
import { DiceRollMessage } from './DiceRoll'

let ws = null
let state = null
let messages = []
let gameId = '<not connected>'
let showGameId = false
let terminationReason = null
let connectionInProgress = false

// The term 'action' means a message that requires waiting before it is completed,
// either a choices list or a dice roll

// Array of functions to be called upon action completion
let actionFinishedCallbacks
// Whether we are currently waiting for an action to complete before displaying more messages
let isActionInProgress
// Whether we already scrolled since the last action was clicked
let didScrollYet

const tryScrollHere = vnode => {
  if (!didScrollYet) {
    didScrollYet = true
    window.setTimeout(() => {
      window.scroll({
        top: offset(vnode.dom).top - 10,
        left: 0,
        behavior: 'smooth'
      })
    }, 100);
  }
}

const startAction = () => {
  isActionInProgress = true
}

const finishAction = () => {
  isActionInProgress = false
  didScrollYet = false

  while (!isActionInProgress && actionFinishedCallbacks.length > 0) {
    const callback = actionFinishedCallbacks.shift()
    callback()
  }
}

const mailingList = {
  reset: function() {
    this.active = false
    this.status = ''
  },
  submitted: function() {
    return this.status == 'DECLINED' || this.status == 'ACCEPTED'
  }
}
mailingList.reset()

const send = data => {
  ws.send(data)
  finishAction()
}

const MailingListPrompt = {
  oninit: vnode => {
    vnode.state.email = ''
  },
  view: vnode => m('.mailing-list-container', [
    m('.mailing-list-title', 'Mailing List'),
    m('.mailing-list-text',
      'If you enjoyed playing the game so far, please consider submitting your email to the ' + 
      'mailing list so that we can contact you later if or when more information becomes ' +
      'available.'
    ),
    m('input.mailing-list-prompt' + (mailingList.submitted() ? '.submitted' : '.active'), {
      placeholder: 'john.doe@example.com',
      value: mailingList.status == 'DECLINED' ? '(declined)' : undefined,
      readonly: mailingList.submitted(),
      oninput: m.withAttr('value', email => vnode.state.email = email)
    }),
    m('.mailing-list-choice-wrapper', [
      m('button.mailing-list-choice',
        {
          onclick: () => {
            send(vnode.state.email)
          }
        },
        'Submit'
      ),
      m('button.mailing-list-choice',
        {
          onclick: () => {
            send('')
          }
        },
        'Decline'
      )
    ]),
    m('.mailing-list-response', mailingList.status == 'FORMATTING_ERROR' && 'Please enter a valid email address.')
  ])
}

const progressFrames = [
  '...',
  '...',
  '\xB7..',
  '.\xB7.',
  '..\xB7'
]
const ProgressIndicator = {
  oninit: vnode => {
    vnode.state.timer = 0
    vnode.state.interval = setInterval(() => {
      vnode.state.timer++
      m.redraw()
    }, 200)
  },
  onremove: vnode => {
    clearInterval(vnode.state.interval)
  },
  view: vnode => m('.progress-indicator', progressFrames[vnode.state.timer % progressFrames.length])
}

const ConnectingState = {
  view: vnode => m('.container-inner',
    m('.connecting', [
      'Connecting to server',
      m(ProgressIndicator)
    ])
  )
}

const Footer = {
  view: vnode => m('footer', [
    m('.copyright', '\xA9 Max Battle 2018-2019'),

    m('.game-id', [
      showGameId && 'Game Session ID: ' + gameId,
      showGameId && m('.game-id-button', { onclick: () => showGameId = false }, '(Hide)'),
      !showGameId && m('.game-id-button',  { onclick: () => showGameId = true }, 'Show Session ID'),
    ])
  ])
}

const InGameState = {
  view: vnode => [
    m('.container-inner', [
      m('.game-title', 'Valorfell: Ragnarök'),
      m(MessageList, { messages }),
      mailingList.active && m(MailingListPrompt)
    ]),
    m(Footer)
  ]
}

const DisconnectedMessage = {
  view: vnode => connectionInProgress
    ? m('div', 'Could not connect to server.')
    : m('div', [
        m('.termination-message', 'Connection to server ended.'),
        m('.termination-reason', terminationReason === null ? '' : 'Reason: ' + terminationReason)
      ])
}

const DisconnectedState = {
  view: vnode => [
    m('.container-inner',
      m('.disconnected', [
        m(DisconnectedMessage),
        m('.retry.choice', { onclick: initWs }, 'reconnect'),
      ])
    ),
    m(Footer)
  ]
}

const ServerDisabledState = {
  view: vnode => [
    m('.container-inner',
      m('.disconnected', [
        m('p', 'To save on costs, the game servers have been shut down for now.'),
        m('p', 'A new iteration of this game is currently being developed and will be available to play in the future.'),
        m('p', [
          'For any questions please contact ',
          m('a.email[href="mailto:m4xematical@gmail.com"]', 'm4xematical@gmail.com'),
          '.'
        ])
      ])
    ),
    m(Footer)
  ]
}

//state = ConnectingState
state = ServerDisabledState
m.mount(document.body, {
  view: vnode =>
    m('.container', m(state))
})

const wsUrl = 'wss://server.valorfell.com'
// const wsUrl = 'ws://localhost:8080'

const initWs = () => {
  ws = new WebSocket(wsUrl)
  terminationReason = null
  gameId = null
  messages = []
  connectionInProgress = true
  showGameId = false
  state = ConnectingState
  
  actionFinishedCallbacks = []
  isActionInProgress = false
  didScrollYet = false

  mailingList.reset()

  ws.onclose = (e) => {
    state = DisconnectedState
    m.redraw()
  }
  ws.onopen = () => {
    connectionInProgress = false
    state = InGameState
  }

  // Either handle the message immediately, or defer it for later if we are waiting on an action
  const handleMsg = (object, handler) => {
    if (isActionInProgress) {
      actionFinishedCallbacks.push(() => handleMsg(object, handler))
    } else {
      handler(object)
    }
  }

  const handleText = object => {
    object.contents.split(/\n{2,}/).forEach((contents) => {
      messages.push({
        contents,
        component: TextMessage,
        oncreate: tryScrollHere
      })
    })
  }

  const handleChoices = object => {
    startAction()
    messages.push({
      ...object,
      send,
      oncreate: tryScrollHere,
      component: ChoicesList
    })
  }

  const handleDiceRoll = object => {
    startAction()
    messages.push({
      ...object,
      component: DiceRollMessage,
      oncreate: tryScrollHere,
      oncomplete: finishAction
    })
  }

  const handleMailingListAppeared = () => {
    mailingList.reset()
    mailingList.active = true
  }

  const handleMailingListStatus = object => {
    mailingList.status = object.response
    if (mailingList.submitted()) {
      mailingList.active = false
    }
  }

  const handleData = (data) => {
    try {
      const object = JSON.parse(data)
      if (object.type == 'text') {
        handleMsg(object, handleText)
      } else if (object.type == 'dice_roll') {
        handleMsg(object, handleDiceRoll)
      } else if (object.type == 'choice') {
        handleMsg(object, handleChoices)
      } else if (object.type == 'client_id') {
        gameId = object.id
      } else if (object.type == 'terminated') {
        terminationReason = object.reason
      } else if (object.type == 'mailing_list') {
        handleMsg(object, handleMailingListAppeared)
      } else if (object.type == 'mailing_list_response') {
        handleMsg(object, handleMailingListStatus)
      } else {
        console.error('Unhandled data', data)
      }
      m.redraw()
    } catch (e) {
      console.error('Error handling data: ', data, '\n', e)
    }
  }

  ws.onmessage = ({ data }) => {
    handleData(data)
  }
}

window.onbeforeunload = () => ws.close()
//initWs()
